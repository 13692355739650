/*
* 
* @author Jamilton Damasceno
*/

/* Layout
-----------------------------*/
html, body, #home {
	height: 100%;
	overflow-x: hidden;
}

body {
	background: url(../../images/bg.jpg);
	background-attachment: fixed;
	font-family: "Trebuchet MS", Helvetica, sans-serif;
	font-size: 1rem;
}

/* Barra de navegação
-----------------------------*/
nav.navbar-transparente {
	padding: 15px 0px;
	background: rgba(0,0,0,0.6);
}

.navbar-light .navbar-nav .nav-link {
	color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
	color: #f7b600
}

.navbar-light .navbar-toggler {
    color: rgba(255,255,255,.5);
    border-color: rgba(255,255,255,0.5);
}

.divisor {
	width: 1px;
	background: white;
	margin: 12px 15px;
}

/* Capa
-----------------------------*/
.capa {
	text-align: center;
}

.btn-custom {
	color: white;
	border-radius: 500px;
	-webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    text-transform: uppercase;
    transition: background 0.4s, color 0.4s;
    padding: 10px 20px;
}

.btn-roxo {
	background: #7c25f8;
}

.btn-roxo:hover {
	background: #6207e3;
	color: white;
}

.btn-branco {
	border: 2px solid white;
}

.btn-branco:hover {
	background-color: white;
	color: black;
}

.carousel-control-next, .carousel-control-prev {
	width: 5%;
}

/* Estrutura conteúdos
-----------------------------*/
.caixa {
	padding-top: 20px;
	padding-bottom: 20px;
}

/* Rodapé
-----------------------------*/
footer {
	background: #000;
	padding: 50px 0px 20px 0px;
	position: relative;
}

footer h4 {
	color: #919496;
	font-size: 0.8em;
	text-transform: uppercase;
}

footer .navbar-nav a {
	color: white;
	font-size: 0.8em;
}

footer .navbar-nav a:hover {
	color: #f7b600;
	text-decoration: none;
}

footer ul {
	list-style: none;
}

footer ul li {
	float: right;
	margin: 0 5px;
}

/* Tipografia
-----------------------------*/
h1 {
	font-weight: 900;
	letter-spacing: -0.05em;
	margin-bottom: 50px;
	color: white;
}

h2 {
	font-size: 3em;
	font-weight: 700;
	letter-spacing: -0.04em;
}

h3 {
	font-size: 2em;
	font-weight: 700;
	letter-spacing: -0.04em;
}



/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
	.btn-custom {
		margin: 10px 15px;
		font-size: 1em;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
	h1 {
		font-size: 3em;
	}
	.btn-custom {
		margin: 10px 15px;
	}
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
	h1 {
		font-size: 4em;
	}
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
	h1 {
		font-size: 5em;
	}
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
	h1 {
		font-size: 6em;
	}
}

#area-cabecalho {
	background-image: url(../../images/bg.jpg);
	padding: 15px;
	text-align: center;
}


#area-menu a{
	font-size: 20px;
}

#area-principal {
	/*width: 920px;*/
	margin: 0 auto;
	padding: 1rem;
}

#area-postagens {
	/*width: 920px;*/
	float: center;
	text-align: center;
}

#area-sobrenos {
	/*width: 920px;*/
	float: center;
	text-align: center;
}

article#localizacao {
	float: center;
	/*width: 880px;*/
	margin-top: 1rem;
}

article#sobrenos {
	float: center;
	/*width: 880px;*/
}

#area-lateral {
	width: 240px;
	float: right;
}

.postagem-sobrenos {
	padding: 1.1rem;
	margin-bottom: 1.1rem;
	background: #20202067;
}

.postagem{
	padding: 1.1rem;
	margin-bottom: 1.1rem;
	background: #20202067;

}

#rodape {
	clear: both;
	text-align: center;
	padding: 1rem;
	background: #202020c7;
	color: #f7b600;
}

/*
**** Formatação do menu ****
*/

.postagem a{
	display: flex;
}

.link {
	text-decoration: none;	
	color: white;
	padding-left: 1rem;
	display: block;
	text-align: center;
}

.textoptions {
	color: #f7b600;
}

#emailcontato a{
	color: #FFF;
}

.link a:link, a:visited {

}

.link:hover {
	text-decoration: none;
	color:white;
}

.link a:hover {
	text-decoration: underline;
}

#area-cabecalho a:link, #area-cabecalho a:visited {
	color: #FFF;
	padding: 8px 12px;
}

#area-cabecalho a:hover {
	color: #f7b600;
	text-decoration: none;
}

/*
**** Formatações em geral ****
*/
.tituloprincipal {
	color: #4e4e4e;
	font-size: 2.5em;
}

.titulosecundario {
	color: #f7b600;
	padding: 1px;
}

.tituloterciario {
	color: #000;
	padding: 5px;
}

.branco {
	color: white;
}

.data-postagem {
	font-size: 1rem;
	border-bottom: 1px solid #f4f4f4;
	padding-bottom: 10px;
	margin-bottom: 10px;
	display: block;
	color: #FFF;
	padding-bottom: 28px;
	text-indent: 1em;
}

.texto-sobrenos {
	font-size: 1rem;
	display: block;
	color: #FFF;
}

.subtitulo {
	height: 1.1rem;
	
}

.subtitulo .data-postagem {
    margin-bottom: 1rem;
}

.paragrafo{
	color: white;
}

.celular{
	color: #f7b600;
	padding-right: 265px;

}

.horario{
	color: #f7b600;
	padding-right: 265px;

}

.data{
	color: #f7b600;
	padding-right: 280px;

}

.nome{
	color: #f7b600;
	padding-right: 270px;
}

.email{
	color: #f7b600;
	padding-right: 275px;
}

.repetesenha{
	color: #f7b600;
	padding-right: 215px;
}



#botao{
	width:38%;
	padding: 15px 0;
	background: #f7b500e8;
	color: white;
	font-weight: bold;
	font-size: 18px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition-duration: 0.4s;
	
}

#botao:hover{
	background-color: white;
	color: #f7b500e8;
}

.formulario {
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	width: 326px;
	background: #f7b500e8;
	border: 1px solid #000;
	border-radius: 25px;
	transition: all 0.5s;
	padding-left: 1%;
	color: white;
	height: 37px;
	font-size: 1.1em;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: white;
	font-size: 0.9em;
	opacity: 0.8; /* Firefox */
}

body.home a#home,
body.contato a#contato,
body.sobre a#sobre,
body.login a#login {
    color: #f7b600;
    cursor: text;
}
 
.caixa {
	margin: 10px 0;
	padding: 5px;
	background: #20202067;
}

.postagem-sobrenos{
	text-align: center;
}

.negrito {
	color: #fff;
}


.modal {
	display: none;
	position: fixed;
	z-index: 1;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
}
  
.modal-content {
	background-color: #232321;
	color: white;
	margin: auto;
	padding: 20px;
	border: 1px solid #888;
	width: 80%;
  }
  
.close {
	color: red;
	float: right;
	font-size: 28px;
	font-weight: bold;
}
  
.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.optionspagamento{
	font-size: 1.2rem;
	font-weight: bold;
}

#wrapper {
    min-height: 100vh; /* altura do rodapé */
}

.fixed-bottom {
	position: fixed;
	width: 100%;
}

.label-avatar{
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  
  .postImage input{
    cursor: pointer;
    display: none;
  }

.postImage {
    width: 280px;
    height: 280px;
    cursor: pointer;
}